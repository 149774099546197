.scan-main {
  display: flex;
  flex-direction: column;
  align-items: center;

  video {
    border-radius: 2em;
    box-shadow: 0 0 16px 0 #ccc;
  }

  .app_logos {
    display: flex;
    justify-content: center;
    padding-bottom: 0.5em;

    img {
      height: 40px;
      padding: 0 1em;
    }
  }

  .qr-wrapper {
    padding: 2em 0;
    width: 40em;
    max-width: 100%;

    section {
      display: inline-block;
      margin-bottom: 1em;
    }
  }

  .three-g-info {
    margin-bottom: 0.5em;
    justify-content: center;
    width: 80%;
    > div {
      background-color: white;
      border-radius: 0.5em;
      margin: 0.5em;
      padding: 1em;

      .card-title {
        margin-bottom: 0.1em;
      }

      span {
        display: block;
      }

      .valid {
        color: #666;
      }
    }
  }

  .certPreview {
    text-align: left;
    max-width: fit-content;
    margin: 2em auto;
    padding: 1em;
    background-color: #fff;
    box-shadow: 0 0 12px 0 #ccc;
    border-radius: 1em;
    overflow-wrap: anywhere;

    span {
      font-weight: bold;
      margin-right: 0.8em;
    }
  }

  .validCert {
    .pill {
      display: inline-block;
      padding: 0.4em 0.9em;
      border: 1px solid;
      border-radius: 1em;
      .spinner-grow,
      svg {
        margin-right: 0.6em;
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }

    .verifying {
      color: #664d03;
      background-color: #fff3cd;
      border-color: #ffecb5;
    }

    .verified {
      color: #0f5132;
      background-color: #d1e7dd;
      border-color: #badbcc;
    }

    .notValid {
      color: #842029;
      background-color: #f8d7da;
      border-color: #f5c2c7;
    }
  }

  .debug {
    font-size: 0.8em;
  }

  .privacy {
    padding-top: 4em;
    color: #999;
    span {
      font-size: 0.8em;
    }
  }
}
