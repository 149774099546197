.navigation-wrapper {
  .navbar-brand {
    cursor: pointer;
  }

  .active {
    color: black;
    font-weight: bolder;
  }

  .nav-link {
    svg {
      margin-right: 0.3em;
    }
  }
}
