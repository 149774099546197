// Colors
$merol-background: #f4fbfa;
$merol-dark: #203f3c;
$merol-dark-hover: darken($merol-dark, 20%);
$merol-light: #529e96;
$merol-light-hover: darken($merol-light, 20%);

// Global Styles
body {
  background-color: $merol-background;
}

.App {
  text-align: center;
}

h1,
h2 {
  padding-top: 1em;
  padding-bottom: 0.6em;
}

a {
  color: $merol-dark;
  &:hover {
    color: $merol-dark-hover;
  }
}

.btn {
  .spinner-border,
  svg {
    margin-right: 0.5em;
  }

  svg {
    margin-top: 3px;
    margin-bottom: 3px;
    width: 18px;
    height: 18px;
  }
}

.btn-merol-dark {
  background-color: $merol-dark;
  border-color: $merol-dark;
  color: white;

  &:hover {
    background-color: $merol-dark-hover;
    border-color: $merol-dark-hover;
    color: white;
  }
}

.btn-outline-merol-dark {
  background-color: transparent;
  border-color: $merol-dark;
  color: $merol-dark;

  &:hover {
    background-color: $merol-dark;
    border-color: $merol-dark;
    color: white;
  }
}

.btn-merol-light {
  background-color: $merol-light;
  border-color: $merol-light;
  color: white;

  &:hover {
    background-color: $merol-light-hover;
    border-color: $merol-light-hover;
    color: white;
  }
}

.btn-outline-merol-light {
  background-color: transparent;
  border-color: $merol-light;
  color: $merol-light;

  &:hover {
    background-color: $merol-light;
    border-color: $merol-light;
    color: white;
  }
}

.button-group {
  button {
    margin: 0.5em;
  }
}

.xxl-button-group {
  display: flex;
  justify-content: center;

  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    margin: 0.4em;
    font-size: 1.1em;
    width: 12em;
    max-width: 50%;

    span {
      font-size: 0.8em;
    }

    svg {
      margin-top: 0;
      margin-bottom: 1em;
      width: 33px;
      height: 33px;
    }
  }
}

footer {
  margin: 4em 0;
  a {
    padding: 1em 1em;
    text-decoration: none;
    color: #999;

    &:hover {
      color: #666;
    }
  }
}
